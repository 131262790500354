<template>
  <div>
    <div v-if="market_area">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <div class="page-header">
          <h4 id="top">Editing Market Area: <small>{{ market_area.name }}</small></h4>
        </div>
      </div>

      <!-- Error Alert -->
      <danger-alert :errors="errors"></danger-alert>
      <form v-on:submit.prevent="editMarketArea">
        <div class="form-group text-left">
          <label for="market-area-title">Market Area Name</label>
          <input
            type="text"
            class="form-control"
            v-model="market_area.name"
            placeholder="name"
            id="market-area-title"
            name="market-area-title"
          />
        <br>
          <div class="form-group text-left">
            <label for="market-area-archived">Is Archived?</label>
            <b-checkbox id="activity-archived" v-model="market_area.is_archived"/>
          </div>
        </div>

        <div class="form-group float-right">
          <div class="btn-group">
            <router-link :to="{ name: 'MarketAreaShow', params: { id: market_area.id } }" tag="a" role="button" class="btn btn-secondary">Back</router-link>
            <input type="submit" class="btn btn-success" value="Update Market Area" />
          </div>
        </div>
      </form>
    </div>
    <loading-overlay v-else></loading-overlay>
  </div>
</template>

<script>

import { MarketAreaService } from '@/common/services/market_area.service';
import DangerAlert from '@/common/components/danger-alert.vue';
import LoadingOverlay from '@/common/components/loading-overlay.vue';

export default {
  name: 'MarketAreaEdit',
  components: {
    DangerAlert,
    LoadingOverlay,
  },
  data() {
    return {
      market_area: null,
      errors: [],
    };
  },
  created() {
    MarketAreaService.get(this.$route.params.id)
      .then(({ data }) => {
        this.market_area = data;
      });
  },
  methods: {
    editMarketArea() {
      const payload = { name: this.market_area.name, is_archived: this.market_area.is_archived };
      MarketAreaService.update(this.$route.params.id, payload)
        .then(() => {
          this.$router.push({ name: 'MarketAreaShow', params: { id: this.market_area.id } });
          this.$notify({
            title: 'Edited MarketArea',
            text: `Successfully edited!`,
            type: 'success',
            width: 350,
          });
        })
        .catch((err) => {
          this.errors = err.response.data.error;
          this.$notify({
            title: 'Could not submit form!',
            text: this.errors.join(' '),
            type: 'error',
            width: 350,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/market_areas";
</style>
